
/* global sketchup:false */

export default {
    methods: {
        getDefinitionsToRenumber(definitions, definitionNamesToProcess, start) {
            let first = parseInt(start);
            let sortedDefinitions = this.sortDefinitions(definitions);
            let toRenumber = [];
            let toRenumberForCurrentDefinitionName = [];
            let currentDefinitionName = null;

            function processToRenumberForCurrentDefinitionName() {
                let usedNumbers = toRenumberForCurrentDefinitionName.map(d => d.definitionNumber);
                let last = first + toRenumberForCurrentDefinitionName.length - 1;
                let next = first;

                toRenumberForCurrentDefinitionName.forEach(d => {
                    if (d.definitionNumber === null || d.definitionNumber < first || d.definitionNumber > last) {
                        while (usedNumbers.includes(next)) next++;
                        usedNumbers.push(next);
                        toRenumber.push({
                            old: d.old,
                            new: d.definitionName + '#' + next,
                        });
                    }
                });
            }

            sortedDefinitions.forEach(definition => {
                let idx = definition.lastIndexOf("#");
                let definitionName = idx === -1 ? definition : definition.substring(0, idx);
                let definitionNumber = idx === -1 ? null : (definition.substring(idx + 1) ? parseInt(definition.substring(idx + 1)) : null);

                if (currentDefinitionName !== definitionName && toRenumberForCurrentDefinitionName.length > 0) {
                    processToRenumberForCurrentDefinitionName();
                    toRenumberForCurrentDefinitionName = [];
                }

                if (!definitionNamesToProcess || definitionNamesToProcess.includes(definitionName)) {
                    currentDefinitionName = definitionName;
                    toRenumberForCurrentDefinitionName.push({
                        old: definition,
                        definitionName: definitionName,
                        definitionNumber: definitionNumber,
                    });
                }
            });
            if (toRenumberForCurrentDefinitionName.length > 0) {
                processToRenumberForCurrentDefinitionName();
            }

            return toRenumber;
        },
        reloadPluginFile(file) {
            sketchup.reloadFile(file);
            window.location.reload();
        },
        sortDefinitions(definitions) {
            definitions.sort(function (a, b) {
                let idxA = a.lastIndexOf('#');
                let idxB = b.lastIndexOf('#');
                if (idxA > -1 && idxB > -1 && a.substring(0, idxA) === b.substring(0, idxB)) {
                    if (parseInt(a.substring(idxA + 1)) < parseInt(b.substring(idxB + 1))) return -1;
                    if (parseInt(a.substring(idxA + 1)) > parseInt(b.substring(idxB + 1))) return 1;
                } else {
                    if (a < b) return -1;
                    if (a > b) return 1;
                }
                return 0;
            });

            return definitions;
        },
    }
}